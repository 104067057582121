.GetStart {
  margin: 40px 0;

  h2{
    text-align: center;
  }
  .GetStartContent {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    p{
      text-align: center;
      width: 300px;
    }
    .BitcoinImage{

      width: 300px;
    }
  }

  .GetStartIcons{
    margin: 0 20px;

    .GetStartIcon{
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 300px;
      h3{
        font-size: small;
      }
      img{
        width: 35px;
      }
    }

  }

  h4{
    background-color: black;
    color: white;
    max-width: 200px;
    text-align: center;
    padding: 10px 20px;
    margin: auto;
  }
}


@media only screen and (min-width: 768px) {

  .GetStart {

    .GetStartContent {
      flex-direction: row;

    }
  }

}