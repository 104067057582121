@import "../../../../Styles/functions";

.Title{
  display: flex;
  flex-direction: column;
  align-items: center;

  h1{
    font-size: small;
    text-align: center;
    font-weight: inherit;
  }
  a{
    @include colorLink;
  }
}

@media only screen and (min-width: 768px) {

  .Title{
    h1{
      font-size: 321.5%;
      text-align: center;
    }
  }

}