.Nav {
  grid-area: Nav;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  z-index: 11;
  max-height: 100px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 20px ;
  .NavItem {

    text-decoration: none;
    border-bottom: 1px solid black;
    font-size: medium;
    width: 100%;
    max-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;


    p {
      padding: 0 5px;
      //max-height: 30px;
    }

    &:hover {
      color: red;
      font-weight: bold;
    }

    &:active {
      color: pink;
    }
  }


}


@media only screen and (min-width: 768px) {

  .Nav {
    border-radius:0;
    position: inherit;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    //background-image: linear-gradient(white, gray, white);
    max-height: 30px;
    background-color: white;

    .NavItem {
      padding: 10px 20px;
      border-bottom: none;
      color: blue;
      font-size: medium;
    }
  }
}