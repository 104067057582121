.Logo{
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  grid-area: Logo;

  a{
    img{
      width: 150px;
    }
  }
  span{
    font-size: x-small;
  }
}


@media only screen and (min-width: 768px){
  .Logo{
    a{
      img{
        width: 250px;
      }
    }
    span{
      font-size: small;
    }
  }

}