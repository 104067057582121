.Header {
  //display: flex;
  //justify-content: space-between;
  //align-items: center;
  //height: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'Logo  LanguageSelector .';
  grid-gap: 20px 0;
  padding: 20px 0;
position: relative;
  .mobileNavResponsive {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
  }
}

@media only screen and (min-width: 767px) {

  .Header {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: 'Logo . Nav LanguageSelector';
    justify-content: center;
    align-items: center;

  }

}

