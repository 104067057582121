.Learn {
  width: 90%;
  margin: auto;
  //text-align: right;
h1{
  text-align: center;
}
  .devicesImage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    //background-image: url('../../../../../images/bitcoin-background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    //background-color: black;
    border-radius: 10px;
    padding: 5px;

    .WindowsMac , .iphoneAndroid{
      background-color: rgba(0,0,0,0.5);
      padding: 10px;
      width: 250px;

    }
    .AllBrowserScreenshot {
      width: 100%;
      max-width: 600px;
      max-height: 300px;

    }
  }

  .StartFaPreview{
    .deviceImg{
      width: 80%;
    }
  }
  .infos {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100px;

    .info {
      max-width: 27%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;

      img {
        width: 40px;
        height: 40px;
      }

      p {
        width: 200px;
        font-size: small;
      }
    }

  }


  .videoExplanation {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //background-image: linear-gradient(gray 5%, gray, white, white, white);
    background-color: black;
    //background-image: url('../../../../../images/bitcoin-background2.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: white;
    text-align: center;
    padding: 20px 0;

    h2 {
      background-color: rgba(0,0,0,0.7);
      border-radius: 20px;
      padding: 10px;
    }

    p {
      max-width: 80%;
      background-color: rgba(0,0,0,0.7);
      padding: 10px;
      border-radius: 20px;
    }

    iframe {
      //width: 80%;
      //height: 50%;
    }
  }


  .downloadBtn {
    background-color: blue;
    padding: 10px 20px;
    color: white;
    text-decoration: none;
    margin: auto;
    //align-items: center;
    border-radius: 5px;
  }





  .howTo{

    //background-color: black;
    //color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .imageLink{
      display: flex;
      justify-content: center;
      .img {
        border-radius: 10px;
        margin: auto;
        width: 90%;
        padding : 50px 10px;
        background-color: black;
      }
    }


  }
}


@media only screen and (min-width: 768px) {

  .Learn {

    .infos {
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .info {

        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-right: solid .2px gray;

        p {
          max-width: 150px;
        }
      }
    }
    .StartFaPreview{
      .deviceImg{
        width: 50%;
      }
    }
    .videoExplanation {
      iframe {
        width: 600px;
        height: 400px;
      }
    }
    .howTo{

      .imageLink{

        .img {
          margin: auto;
          width: 90%;
          padding-top: 50px;
        }
      }

    }

  }

}